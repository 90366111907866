import React from 'react';
import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import { FlexContainer, FlexItem } from '../components/Grid';
import { PHONE, EMAIL, ADDRESS } from '../config';

export default function Contact() {
    return (
        <>
            <title>BARBA - Contact us</title>
            <meta
                name="description"
                content="Together the team at BARBA brings over 20 years of experience in the barbering industry. Our award-winning team is passionate about delivering outstanding work and five-star service to each and every person that walks through our doors."
            />
            <Header isDark />
            <PageTitle title="Contact us" />
            <FlexContainer
                hasGutters={false}
                classes="c-content-image-row u-fbas--stretch"
            >
                <FlexItem columns={12} medium={6} classes="u-fbo--two">
                    <div
                        className="c-content-image-row__image"
                        style={{
                            backgroundImage: 'url(/bus-station-3.jpg)',
                        }}
                    />
                </FlexItem>
                <FlexItem columns={12} medium={6} classes="u-fbo--one">
                    <Container>
                        <div className="c-content-image-row__content c-content-image-row__content--dark">
                            <div>
                                <h4 className="u-font--heading-1 u-mb--xlarge">
                                    Opening times
                                </h4>
                                <p className="u-mb--xlarge">
                                    <strong>Monday</strong>: Closed <br />
                                    <strong>Tuesday</strong>: 10am – 6pm <br />
                                    <strong>Wednesday</strong>: 10am – 6pm
                                    <br />
                                    <strong>Thursday</strong>: 12pm – 8pm <br />
                                    <strong>Friday</strong>: 10am – 6pm <br />
                                    <strong>Saturday</strong>: 9am – 5pm <br />
                                    <strong>Sunday</strong>: Closed <br />
                                </p>

                                <h4 className="u-font--heading-1 u-mb--xlarge">
                                    Get in touch
                                </h4>
                                <p className="u-font--body u-mb--xlarge">
                                    If you have any queries don't hesitate to
                                    call us on{' '}
                                    {PHONE && (
                                        <strong>
                                            {' '}
                                            <a href={`tel:${PHONE}`}>{PHONE}</a>
                                        </strong>
                                    )}
                                </p>

                                <p>
                                    <strong>Email</strong>:{' '}
                                    <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                                </p>
                                <p>
                                    <strong>Address</strong>: {ADDRESS}
                                </p>
                            </div>
                        </div>
                    </Container>
                </FlexItem>
            </FlexContainer>
            <Footer />
        </>
    );
}
