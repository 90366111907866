import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';

const PageTitle = (props) => {
    const { title } = props;

    return (
        <section className="c-page-title">
            <Container narrow>{title}</Container>
        </section>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageTitle;
